<template>
  <div id="tackRest">
    <div class="estimate-box">
      <div class="title">预计营业时间</div>
      <p @click="showCalendar = true">{{dateVal}}</p>
    </div>

    <div class="rest-dialog">
      <div class="title">歇业通知 (选填)</div>
      <div class="area-box">
        <textarea v-model="reason" @input="reasonInput" placeholder="请简单说明一下歇业原因，内容不超过200字"></textarea>
        <p class="tips">{{reasonNum}}</p>
      </div>
    </div>

    <p class="point">歇业后将停止自动接单，并且暂停用户访问。如需恢复营业，请在“首页左上角菜单 - 恢复营业”手动修改营业状态。</p>

    <div class="btn" @click="submit">开始歇业</div>

    <van-popup v-model="showCalendar" position="bottom">
      <van-datetime-picker
        @confirm="onConfirm"
        v-model="currentDate"
        type="datetime"
        :min-date="minDate"
        :max-date="maxDate"
      />/>
    </van-popup>
  </div>
</template>

<script>
import { shopRest } from "../api/requist";
import { Toast } from "vant";
export default {
  components: {},
  props: [],
  data() {
    return {
      a: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      title: "歇业休息",
      dateVal: "请输入日期",
      showCalendar: false,
      reason: "",
      reasonNum: "0/200"
    };
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    onConfirm(date) {
      let h = date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
      let m =
        date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
      let M =
        date.getMonth() + 1 >= 10
          ? date.getMonth()
          : "0" + (date.getMonth() + 1);
      let D = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();

      this.dateVal = `${date.getFullYear()}年${M}月${D}日${h}:${m}`;

      this.showCalendar = false;
    },

    reasonInput() {
      if (this.reason.length >= 200) {
        this.reason = this.reason.slice(0, 200);
      }
      this.reasonNum = this.reason.length + "/200";
    },

    async submit() {
      let that = this;
      if (this.dateVal === "请输入日期") {
        return Toast("请填写预计营业时间");
      }

      let time = this.dateVal.replace("年", "-");
      time = time.replace("月", "-");
      time = time.replace("日", " ");
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem('merch_id'));
      formData.append("business_status", 0);
      formData.append("expected_business_hours", time);
      formData.append("notice_of_closing", this.reason);
      let res = await shopRest(formData);

      if (res.code === 200) {
        Toast({
          message: "歇业成功",
          onClose: function() {
            that.$router.push({ path: "/index" });
          }
        });
      } else {
        Toast(res.message);
      }
    }
  }
};
</script>
<style lang="scss" >
 @import '../common/css/theme.scss';
#tackRest {
  min-height: 100vh;
  background: rgba(245, 247, 250, 1);
  .estimate-box {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding: 0 4.27* 3.75px;
    margin: 3.2* 3.75px auto;
    color: #303133;
    font-size: 3.73* 3.75px;
    height: 9.6* 3.75px;
    background-color: #fff;
  }

  .rest-dialog {
    background: rgba(255, 255, 255, 1);
    padding: 2.13* 3.75px 4.27* 3.75px 4.27* 3.75px;
    height: 51.2* 3.75px;
    box-sizing: border-box;
    .title {
      text-align: left;
      color: rgba(96, 98, 102, 1);
      font-size: 3.73* 3.75px;
      margin-bottom: 2.13* 3.75px;
    }

    .area-box {
      height: 37.33* 3.75px;
      background: rgba(245, 247, 250, 1);
      border-radius: 1* 3.75px;
      padding: 3.2* 3.75px;
      box-sizing: border-box;
      textarea {
        width: 81.6* 3.75px;
        height: 21.33* 3.75px;
        font-size: 3.73* 3.75px;
        font-weight: 400;
        line-height: 4.27* 3.75px;
        color: #303133;
        outline: none;
        border: none;
        resize: none;
        background: rgba(245, 247, 250, 1);
        color: #909399;
        font-size: 3.73* 3.75px;
        &::placeholder {
          color: #909399;
          font-size: 3.73* 3.75px;
        }
      }

      .tips {
        text-align: right;
        margin-top: 5.07* 3.75px;
        font-size: 3.2* 3.75px;
        font-weight: 400;
        line-height: 4.27* 3.75px;
        color: rgba(48, 49, 51, 1);
      }
    }
  }

  .point {
    margin: 3.2* 3.75px auto 8.53* 3.75px;
    padding: 0 4.27* 3.75px;
    font-size: 3.2* 3.75px;
    color: rgba(144, 147, 153, 1);
    line-height: 4.27* 3.75px;
    text-align: left;
    box-sizing: border-box;
  }

  .btn {
    width: 91.47* 3.75px;
    height: 10.67* 3.75px;
    background: $theme-color;
    border-radius: 1* 3.75px;
    font-size: 3.73* 3.75px;
    text-align: center;
    margin: 0 auto;
    line-height: 10.67* 3.75px;
    color: rgba(255, 255, 255, 1);
  }
}
</style>
